<template>
  <div class="topnav has-background-info">
    <div class="logo-wrapper">
      <img :src="topLogo" alt="The Day And Night Transport Refrigeration Logo. Left half white, right half black.">
    </div>
    <div class="lower-bar">
      <div class="lower-bar-item" v-for="(button, index) in buttons" :key="index">
          <div class="button is-outlined is-fullwidth is-small" @click="scrollTo(button.scrollId)">
          <span class="icon">
            <img :src="button.icon"/>
          </span>
          <span>{{ button.text }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import topLogo from '../assets/logo-200.png'
import thumbsUpIcon from '../assets/icons/thumbs-up.svg'
import servicesIcon from '../assets/icons/services.svg'
import phoneIcon from '../assets/icons/phone.svg'

export default {
  name: 'TopNav',
  setup: function () {
    const buttons = [
      {
        scrollId: 'whyChoose',
        text: 'Why Choose Us?',
        icon: thumbsUpIcon
      },
      {
        scrollId: 'services',
        text: 'Services',
        icon: servicesIcon
      },
      {
        scrollId: 'contactUs',
        text: 'Contact Us',
        icon: phoneIcon
      }
    ]

    const scrollTo = function (scrollId) {
      const element = document.querySelector(`[scrollId="${scrollId}"]`)
      element.scrollIntoView({ behavior: 'smooth' })
    }

    return {
      topLogo,
      buttons,
      scrollTo
    }
  }
}
</script>

<style scoped>
  .topnav {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .logo-wrapper {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
  }

  .logo-wrapper > img {
    max-height: 100px;
    display: block;
    margin: 0 auto;
  }

  .lower-bar {
    display: flex;
    flex-direction: row;
    padding: 4px 2px 4px 2px;
  }

  .lower-bar-item {
    flex: 1;
    text-align: center;
    margin: 0 2px 0 2px;
  }

  .icon > img {
    height: 12px;
    width: 12px;
  }
</style>
