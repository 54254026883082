<template>
  <div class="images-wrapper">
    <div class="images-holder">
      <img v-for="(image, index) in imageList" :src="image.image" :key="index" @click="selectImage(index)" :alt="image.alt" :title="image.desc" draggable="false" />
    </div>
  </div>
  <div class="modal is-active" v-if="selectedIndex != null">
    <div class="modal-background" @click="selectedIndex = null"></div>
      <div class="modal-card">
        <div class="control-overlay">
          <div class="control-close" @click="selectedIndex = null">
            <img src="../assets/icons/close.svg" />
          </div>
          <div class="control-button" @click="selectImage(selectedIndex - 1)">
            <img src="../assets/icons/chevron-left.svg" />
          </div>
           <div class="control-button pulled-right" @click="selectImage(selectedIndex + 1)">
            <img class="pulled-right" src="../assets/icons/chevron-right.svg" />
          </div>
        </div>
        <div class="modal-image-wrapper p-1 has-background-white is-flex is-flex-direction-column">
          <img :src="imageList[selectedIndex].image" :alt="imageList[selectedIndex].alt" :title="imageList[selectedIndex].desc" />
          <div class="modal-image-desc is-size-6 p-1 has-text-weight-bold has-text-centered" v-if="imageList[selectedIndex].desc != ''">
            {{ imageList[selectedIndex].desc }}
          </div>
        </div>
    </div>
  </div>
</template>

<script>
import thermokingPicture from '../assets/thermoking-400.png'
import carrierPicture from '../assets/carrier-400.png'
import truckPicture from '../assets/top-photos/truck.jpeg'
import filterPicture from '../assets/top-photos/filter.jpeg'
import fluidPicture from '../assets/top-photos/fluid.jpeg'

import { ref } from 'vue'

export default {
  name: 'Pictures',
  setup: function (props, context) {
    const selectedIndex = ref(null)
    const imageList = [
      {
        image: thermokingPicture,
        desc: 'Our technicians have extensive dealer training servicing Thermo King products.',
        alt: 'A photo of a Thermo King refrigeration unit'
      },
      {
        image: carrierPicture,
        desc: 'Our technicians have professional training servicing Carrier products',
        alt: 'A photo of a Carrier refrigeration unit'
      },
      {
        image: truckPicture,
        desc: '',
        alt: 'A photo of our truck'
      },
      {
        image: filterPicture,
        desc: '',
        alt: 'A photo of a reefer with a brand new filter'
      },
      {
        image: fluidPicture,
        desc: '',
        alt: 'A photo of reefer during service'
      }
    ]

    const selectImage = function (index) {
      if (index > imageList.length - 1) {
        index = 0
      } else if (index < 0) {
        index = imageList.length - 1
      }
      selectedIndex.value = index
    }

    return {
      imageList,
      selectedIndex,
      selectImage
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.images-wrapper {
  position: relative;
  display: flex;
  flex-direction: row;
  height: 200px;
  width: 100%;
  overflow: hidden;
  background: white;
  padding: 5px;
}

.control-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  cursor: pointer;
  padding: 5px;
}

.control-close {
  z-index: 10000;
  position: absolute;
  right: 5px;
  top: 5px;
  height: 30px;
  width: 30px;
  background: rgba(255,255,255, 0.1);
  border-radius: 2px;
}

.control-close img {
  height: 30px;
  width: 30px;
}

.control-button {
  padding: 5px;
  width: 50%;
  height: 100%;
  float: left;
  display: flex;
  align-items: center;
  text-align: center;
}

.control-button > img.pulled-right {
  right: 10px;
}

.control-button img{
  position: absolute;
  width: 25px;
  height: 25px;
  background: rgba(255,255,255, 0.1);
  border-radius: 2px;

}

.images-holder {
  flex: 1;
  display: flex;
  flex-direction: row;
  overflow-y: hidden;
  overflow-x: auto;
}

.images-holder > img {
  flex: none;
  height: 100%;
  padding: 0 2px 0 2px;
  margin: 0 auto;
}
</style>
