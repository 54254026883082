<template>
  <section class="hero is-small is-info" scrollId="whyChoose">
    <div class="hero-body">
      <p class="title">
        Why Choose
      </p>
      <p class="subtitle ml-2">
        Day and Night Transport Refrigeration
      </p>
    </div>
  </section>
  <section>
    <div class="px-3 py-4">
      <p class="mx-3 has-text-centered">
        &ldquo;We at Day And Night have been providing excellent service to customers in the Greater Toronto Area since 2008. Our mission is simple: We aim to the combine high quality service and repair, with the same warranty you've come to expect from a dealer, at a very competitive rate.&rdquo;
      </p>
      <p class="has-text-centered mt-2 is-italic has-text-weight-bold">Andy Pona, Owner & Operator</p>
      <hr class="px-3">
      <nav class="level">
        <div class="level-item has-text-centered" v-for="(level, index) in levels" :key="index">
          <div>
            <p class="heading">{{ level.top }}</p>
            <p class="title" v-if="level.onClick === undefined">
              {{ level.bottom }}
            </p>
            <div class="button is-dark has-text-weight-bold is-outlined" v-else @click="level.onClick">
              {{ level.bottom }}
            </div>
          </div>
        </div>
      </nav>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Levels',
  setup: function () {
    const levels = [
      {
        top: 'Years operating in the GTA',
        bottom: '13'
      },
      {
        top: 'Services Completed',
        bottom: '10,000+'
      },
      {
        top: 'Competitive Prices',
        bottom: '$$$ Saved'
      },
      {
        top: 'Customer Loyalty Program',
        bottom: 'See Below',
        onClick: function () {
          const element = document.querySelector('[scrollId="CustomerLoyaltyProgram"]')
          element.scrollIntoView({ behavior: 'smooth' })
        }
      }
    ]
    return {
      levels
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
</style>
